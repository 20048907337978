#components-layout-demo-responsive .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
  }
  
  .site-layout-sub-header-background {
    background: #fff;
  }
  
  .site-layout-background {
    background: #fff;
  }

@media screen and (max-width: 991px) {
    .ant-layout-sider {
        position: absolute;
        z-index: 2000;
        height: 100vh;
    }
}

