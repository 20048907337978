.input {
    border-radius: 50px;
}

.button-primary {
    width: 100%;
    border: none;
    color: white !important;
    text-transform: uppercase;
    align-items: center;
    background: rgb(0,199,73)!important;
    background: linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)!important;
}

.button-secondary {
    width: 100%;
    border: none;
    color: rgb(0,199,73) !important;
    text-transform: uppercase;
    align-items: center;
    background: white !important;
    background: white !important;
}

.textWhite .ant-input {
    color: white;
}

.placeholderWhite .ant-input::placeholder {
    color: white;
}

.flexEndInputControl .ant-col.ant-form-item-control {
    justify-content: flex-end;
}