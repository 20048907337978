.customTabBarWhite .ant-tabs-ink-bar {
    background-color: white!important;
}

.customTabBarWhite .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white!important;
}

.searchTabBar .ant-tabs-ink-bar {
    background-color: #00F93E!important;
}

.searchTabBar .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #00F93E!important;
    font-weight: bold;
}

.searchTabBar .ant-tabs-tab {
    color: #CDCDCD !important;
    font-weight: bold;
}

.searchTabBar .ant-tabs-content-holder {
    display: flex;
}