body {
  font-family: 'Montserrat', sans-serif;
}

#root {
  min-height: 100vh;
}

.logo {
	height: 32px;
	margin: 16px;
}

.pd {
  padding: 20px;
}

.resultContentTransparent .ant-result-content {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
}

.headerComponent .ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 10px;
}